body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--th-background);
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

input::-moz-focus-outer {
  color: none;
}

/* width utilities */
@layer utilities {
  @variants responsive {
    .w-8\/10 {
      width: 80%;
    }

    .w-9\/10 {
      width: 90%;
    }
    .w-6\/10vw {
      width: 60vw;
    }
    .w-8\/10vw {
      width: 80vw;
    }
    .w-17\/20vw {
      width: 85vw;
    }
    .w-19\/20vw {
      width: 95vw;
    }
    .w-9\/10vw {
      width: 95vw;
    }
    .min-w-15\.75rem {
      min-width: 15.75rem;
    }
    .min-w-4\/10 {
      min-width: 40%;
    }
    .min-w-5\/10 {
      min-width: 50%;
    }
    .min-w-17\/20 {
      min-width: 85%;
    }
  }
}

/* height utilities */
@layer utilities {
  @variants responsive {
    .h-9\/10 {
      height: 90%;
    }
    .h-1\/2vh {
      height: 50vh;
    }
    .h-6\/10vh {
      height: 60vh;
    }
    .h-8\/10vh {
      height: 80vh;
    }
    .h-6\/10vw {
      height: 60vw;
    }
    .h-8\/10vw {
      height: 80vw;
    }
    .h-17\/20vw {
      height: 85vw;
    }
    .h-9\/10vw {
      height: 90vw;
    }
    .h-19\/20vw {
      height: 95vw;
    }
    .max-h-md {
      max-height: 448px;
    }
  }
}

/* fontsize utilities */
@layer utilities {
  @variants responsive {
    .text-20px {
      font-size: 20px;
    }
  }
}
/* circle utilities */
@layer utilities {
  @variants responsive {
    .half-circle-right {
      border-radius: 0 150px 150px 0;
      border: 1.5px solid black;
      border-left: none;
    }
    .half-circle-left {
      border-radius: 150px 0 0 150px;
      border: 1.5px solid black;
      border-right: none;
    }
  }
}

/* scrollbar utilities */
@layer utilities {
  @variants responsive {
    .scroll-mandatory-x {
      scroll-snap-type: x mandatory;
    }
    .scroll-mandatory-y {
      scroll-snap-type: y proximity;
    }
    .hide-scrollbar::-webkit-scrollbar {
      display: none;
    }
    .scroll-child-start {
      scroll-snap-align: start;
    }
  }
}

/* transform utilities */
@layer utilities {
  @variants responsive {
    .translate-y-3px {
      transform: translateY(3px);
    }
    .translate-y-2x-full {
      transform: translateY(200%);
    }
  }
}

/* position utilities */
@layer utilities {
  @variants responsive {
    .-left-15px {
      left: -15px;
    }
    .top-15px {
      top: 15px;
    }
  }
}

/* text utilities */
@layer utilities {
  @variants responsive {
    .text-end {
      text-align: end;
    }
  }
}

/* animation  */
